import {
    ADD_TESTREQUEST_TEST, ADD_TESTREQUEST_TEST_FAIL, ADD_TESTREQUEST_TEST_SUCCESS,
    ADD_TESTREQUEST_SAMPLE, ADD_TESTREQUEST_SAMPLE_FAIL, ADD_TESTREQUEST_SAMPLE_SUCCESS,
    DELETE_TESTREQUEST_SAMPLE, DELETE_TESTREQUEST_SAMPLE_FAIL, DELETE_TESTREQUEST_SAMPLE_SUCCESS,
    DELETE_TESTREQUEST_TESTS, DELETE_TESTREQUEST_TESTS_FAIL, DELETE_TESTREQUEST_TESTS_SUCCESS,
    GET_REQUESTS, GET_REQUESTS_FAIL, GET_REQUESTS_SUCCESS,
    RESET_TESTREQUESTS_SEARCH_QUERY, SET_TESTREQUESTS_SEARCH_QUERY,
    CREATE_NEW_REQUEST, CREATE_NEW_REQUEST_FAIL, CREATE_NEW_REQUEST_SUCCESS,
    GET_TESTREQUEST_DETAIL, GET_TESTREQUEST_DETAIL_FAIL, GET_TESTREQUEST_DETAIL_SUCCESS, EMPTY_TESTREQUEST_DETAIL,
    GET_TESTREQUEST_PATIENT_VISIT_DETAIL, GET_TESTREQUEST_PATIENT_VISIT_DETAIL_SUCCESS, GET_TESTREQUEST_PATIENT_VISIT_DETAIL_FAIL,
    DELETE_REQUESTS, DELETE_REQUESTS_FAIL, DELETE_REQUESTS_SUCCESS,
    UPDATE_TESTREQUESTS, UPDATE_TESTREQUESTS_FAIL, UPDATE_TESTREQUESTS_SUCCESS, GET_TESTREQUEST_TEST_DETAIL, GET_TESTREQUEST_TEST_DETAIL_SUCCESS, GET_TESTREQUEST_TEST_DETAIL_FAIL, EDIT_TESTREQUEST_TEST_SAMPLETYPE, EDIT_TESTREQUEST_TEST_SAMPLETYPE_SUCCESS, EDIT_TESTREQUEST_TEST_SAMPLETYPE_FAIL, GET_TESTREQUEST_PROFILE_DETAIL, GET_TESTREQUEST_PROFILE_DETAIL_SUCCESS, GET_TESTREQUEST_PROFILE_DETAIL_FAIL, GET_TESTREQUEST_PATIENT_VISIT_PROFILE_DETAIL, GET_TESTREQUEST_PATIENT_VISIT_PROFILE_DETAIL_SUCCESS, GET_TESTREQUEST_PATIENT_VISIT_PROFILE_DETAIL_FAIL, GET_TESTREQUEST_SAMPLE, GET_TESTREQUEST_SAMPLE_SUCCESS, GET_TESTREQUEST_SAMPLE_FAIL,
    GET_BILLING_TYPES, GET_BILLING_TYPES_SUCCESS, GET_BILLING_TYPES_FAIL,
    SET_REQUEST_SAMPLE_COLLECTOR, SET_REQUEST_SAMPLE_COLLECT_TIME, SET_REQUEST_SAMPLE_QUALITY,
    UPDATE_REQUEST_GENERAL, UPDATE_REQUEST_GENERAL_SUCCESS, UPDATE_REQUEST_GENERAL_FAIL,
    UPDATE_REQUEST_TESTS, UPDATE_REQUEST_TESTS_SUCCESS, UPDATE_REQUEST_TESTS_FAIL,
    UPDATE_REQUESTSSAMPLE, UPDATE_REQUESTSSAMPLE_SUCCESS, UPDATE_REQUESTSSAMPLE_FAIL,

    GET_TESTREQUEST_GENERAL_DETAIL, GET_TESTREQUEST_GENERAL_DETAIL_SUCCESS, GET_TESTREQUEST_GENERAL_DETAIL_FAIL,
    GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL, GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL_SUCCESS, GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL_FAIL,
    GET_TESTREQUEST_TESTS_DETAIL, GET_TESTREQUEST_TESTS_DETAIL_SUCCESS, GET_TESTREQUEST_TESTS_DETAIL_FAIL,
    GET_TESTREQUEST_SAMPLES_DETAIL, GET_TESTREQUEST_SAMPLES_DETAIL_SUCCESS, GET_TESTREQUEST_SAMPLES_DETAIL_FAIL,
    GET_TESTREQUEST_TESTS_LOADING_COMPLETE,
    CHANGE_REQUEST_SAMPLE_TABLE,
    CHANGE_REQUEST_SAMPLE_TABLE_SUCCESS,
    CHANGE_REQUEST_SAMPLE_TABLE_FAIL,
    SET_REQUEST_SAMPLE_RECEIVER,
    SET_REQUEST_SAMPLE_RECEIVED_TIME,
    SET_REQUEST_SAMPLE_SID,
    SET_REQUEST_SAMPLE_NUMB_OF_LABELS,
    RELOAD_STATE_TESTREQUEST_DETAIL,
    RELOAD_STATE_TESTREQUEST_DETAIL_SUCCESS,
    RELOAD_STATE_TESTREQUEST_DETAIL_FAIL,
    UPDATE_SAMPLE_NUMBOFLABELS,
    UPDATE_SAMPLE_NUMBOFLABELS_FAIL,
    UPDATE_SAMPLE_NUMBOFLABELS_SUCCESS,
    CREATE_GROUP_REQUEST,
    EDIT_TESTREQUEST_SAMPLEID,
    EDIT_TESTREQUEST_SAMPLEID_SUCCESS,
    EDIT_TESTREQUEST_SAMPLEID_FAIL,
    EDIT_TESTREQUEST_NUMBER_OF_LABELS,
    EDIT_TESTREQUEST_NUMBER_OF_LABELS_SUCCESS,
    EDIT_TESTREQUEST_NUMBER_OF_LABELS_FAIL,
    SET_REQUEST_SAMPLE_SID_SUCCESS,
    SET_REQUEST_SAMPLE_SID_FAIL,
    SET_REQUEST_SAMPLE_NUMB_OF_LABELS_SUCCESS,
    SET_REQUEST_SAMPLE_NUMB_OF_LABELS_FAIL,
    CANCEL_TEST_REQUEST,
    CANCEL_TEST_REQUEST_FAIL,
    CANCEL_TEST_REQUEST_SUCCESS,
    GET_PATIENT_VISIT_SUCCESS,
    CLEAR_DETAIL_INFO_PATIENT_VISIT,
    COUNT_PATIENT_AND_TEST,
    COUNT_PATIENT_AND_TEST_SUCCESS,
    COUNT_PATIENT_AND_TEST_FAIL,
    SET_REQUEST_SAMPLE_CLEAR,
    UPDATE_REQUEST_SID,
    UPDATE_REQUEST_SID_SUCCESS,
    UPDATE_REQUEST_SID_FAIL,
    CREATE_NEW_COLLECTOR_AREA,
    CREATE_NEW_COLLECTOR_AREA_SUCCESS,
    CREATE_NEW_COLLECTOR_AREA_FAIL,
    GET_COLLECTOR_AREA,
    GET_COLLECTOR_AREA_SUCCESS,
    GET_COLLECTOR_AREA_FAIL,
    PRINT_SAMPLE_LIST,
    LOADING_PRINT_SAMPLE_LIST,
    UPDATE_REQUEST_WAITING_SUCCESS,
    UPDATE_REQUEST_WAITING_FAIL,
    UPDATE_REQUEST_WAITING,
    RESET_SEARCH_TEST_MANUAL,
    UPDATE_REQUEST_STTGPB,
    UPDATE_REQUEST_STTGPB_FAIL,
    UPDATE_REQUEST_STTGPB_SUCCESS
} from "./actionTypes";

//reset search query
export const resetSearchQuery = () => ({
    type: RESET_TESTREQUESTS_SEARCH_QUERY
})

//set search query
export const setSearchQuery = searchQuery => ({
    type: SET_TESTREQUESTS_SEARCH_QUERY,
    payload: searchQuery
})

//get test request
export const getRequests = payload => ({
    type: GET_REQUESTS,
    payload: payload || {}
})

export const getRequestsSuccess = payload => ({
    type: GET_REQUESTS_SUCCESS,
    payload: payload
})

export const getRequestsFail = error => ({
    type: GET_REQUESTS_FAIL,
    payload: error
})

//add test request
export const createNewRequest = payload => ({
    type: CREATE_NEW_REQUEST,
    payload: payload,
})

export const resetSearchTestManual = payload => ({
    type: RESET_SEARCH_TEST_MANUAL,
    payload: payload,
})

export const createNewRequestSuccess = payload => ({
    type: CREATE_NEW_REQUEST_SUCCESS,
    payload: payload,
})

export const createNewRequestFail = error => ({
    type: CREATE_NEW_REQUEST_FAIL,
    payload: error,
})

export const getTestRequestDetail = id => ({
    type: GET_TESTREQUEST_DETAIL,
    id,
})

export const getTestRequestDetailSuccess = testRequests => ({
    type: GET_TESTREQUEST_DETAIL_SUCCESS,
    payload: testRequests,
})

export const getTestRequestDetailFail = error => ({
    type: GET_TESTREQUEST_DETAIL_FAIL,
    payload: error,
})

export const getTestRequestPatientVisitDetail = id => ({
    type: GET_TESTREQUEST_PATIENT_VISIT_DETAIL,
    id,
})

export const getTestRequestPatientVisitDetailSuccess = testRequests => ({
    type: GET_TESTREQUEST_PATIENT_VISIT_DETAIL_SUCCESS,
    payload: testRequests,
})

export const getTestRequestPatientVisitDetailFail = error => ({
    type: GET_TESTREQUEST_PATIENT_VISIT_DETAIL_FAIL,
    payload: error,
})

export const emptyTestRequestDetail = id => ({
    type: EMPTY_TESTREQUEST_DETAIL,
    id,
})

//test request test
export const deleteTestRequestTest = (payload, callback) => ({
    type: DELETE_TESTREQUEST_TESTS,
    payload: payload,
    callback: callback
})

export const deleteTestRequestTestSuccess = payload => ({
    type: DELETE_TESTREQUEST_TESTS_SUCCESS,
    payload: payload,
})

export const deleteTestRequestTestFail = error => ({
    type: DELETE_TESTREQUEST_TESTS_FAIL,
    payload: error,
})
export const addTestRequestTest = payload => ({
    type: ADD_TESTREQUEST_TEST,
    payload: payload,
})

export const addTestRequestTestSuccess = payload => ({
    type: ADD_TESTREQUEST_TEST_SUCCESS,
    payload: payload,
})

export const addTestRequestTestFail = error => ({
    type: ADD_TESTREQUEST_TEST_FAIL,
    payload: error,
})

//test request sample
export const deleteTestRequestSample = payload => ({
    type: DELETE_TESTREQUEST_SAMPLE,
    payload: payload,
})

export const deleteTestRequestSampleSuccess = payload => ({
    type: DELETE_TESTREQUEST_SAMPLE_SUCCESS,
    payload: payload,
})

export const deleteTestRequestSampleFail = error => ({
    type: DELETE_TESTREQUEST_SAMPLE_FAIL,
    payload: error,
})
export const addTestRequestSample = payload => ({
    type: ADD_TESTREQUEST_SAMPLE,
    payload: payload,
})

export const addTestRequestSampleSuccess = payload => ({
    type: ADD_TESTREQUEST_SAMPLE_SUCCESS,
    payload: payload,
})

export const addTestRequestSampleFail = error => ({
    type: ADD_TESTREQUEST_SAMPLE_FAIL,
    payload: error,
})

// update TEST REQUEST
export const updateTestRequest = payload => ({
    type: UPDATE_TESTREQUESTS,
    payload: payload,
})

export const updateTestRequestSuccess = payload => ({
    type: UPDATE_TESTREQUESTS_SUCCESS,
    payload: payload,
})

export const updateTestRequestFail = error => ({
    type: UPDATE_TESTREQUESTS_FAIL,
    payload: error,
})

// Delete request
export const deleteRequests = payload => ({
    type: DELETE_REQUESTS,
    payload: payload,
})

export const deleteRequestsSuccess = payload => ({
    type: DELETE_REQUESTS_SUCCESS,
    payload: payload,
})

export const deleteRequestsFail = error => ({
    type: DELETE_REQUESTS_FAIL,
    payload: error,
})

// Cancel request
export const cancelTestRequest = payload => ({
    type: CANCEL_TEST_REQUEST,
    payload: payload,
})

export const cancelTestRequestFail = error => ({
    type: CANCEL_TEST_REQUEST_FAIL,
    payload: error,
})

export const cancelTestRequestSuccess = payload => ({
    type: CANCEL_TEST_REQUEST_SUCCESS,
    payload: payload,
})

//get test info
export const getTestRequestTestDetail = payload => ({
    type: GET_TESTREQUEST_TEST_DETAIL,
    payload: payload,
})

export const getTestRequestTestDetailSuccess = payload => ({
    type: GET_TESTREQUEST_TEST_DETAIL_SUCCESS,
    payload: payload,
})
export const getTestRequestTestDetailFail = error => ({
    type: GET_TESTREQUEST_TEST_DETAIL_FAIL,
    payload: error,
})
export const editTestRequestTestSampleType = payload => ({
    type: EDIT_TESTREQUEST_TEST_SAMPLETYPE,
    payload: payload,
})

export const editTestRequestTestSampleTypeSuccess = payload => ({
    type: EDIT_TESTREQUEST_TEST_SAMPLETYPE_SUCCESS,
    payload: payload,
})

export const editTestRequestTestSampleTypeFail = error => ({
    type: EDIT_TESTREQUEST_TEST_SAMPLETYPE_FAIL,
    payload: error,
})
export const getTestRequestProfileDetail = payload => ({
    type: GET_TESTREQUEST_PROFILE_DETAIL,
    payload: payload,
})
export const getTestRequestProfileDetailSuccess = payload => ({
    type: GET_TESTREQUEST_PROFILE_DETAIL_SUCCESS,
    payload: payload,
})
export const getTestRequestProfileDetailFail = error => ({
    type: GET_TESTREQUEST_PROFILE_DETAIL_FAIL,
    payload: error,
})

export const getTestRequestPatientVisitProfileDetail = payload => ({
    type: GET_TESTREQUEST_PATIENT_VISIT_PROFILE_DETAIL,
    payload: payload,
})
export const getTestRequestPatientVisitProfileDetailSuccess = payload => ({
    type: GET_TESTREQUEST_PATIENT_VISIT_PROFILE_DETAIL_SUCCESS,
    payload: payload,
})
export const getTestRequestPatientVisitProfileDetailFail = error => ({
    type: GET_TESTREQUEST_PATIENT_VISIT_PROFILE_DETAIL_FAIL,
    payload: error,
})
//GET SAMPLE INFO
export const getTestRequestSample = (payload, callback) => ({
    type: GET_TESTREQUEST_SAMPLE,
    payload: payload || {},
    callback
})
export const getTestRequestSampleSuccess = payload => ({
    type: GET_TESTREQUEST_SAMPLE_SUCCESS,
    payload: payload,
})
export const getTestRequestSampleFail = error => ({
    type: GET_TESTREQUEST_SAMPLE_FAIL,
    payload: error,
})

// BILLING TYPES
export const getBillingTypes = payload => ({
    type: GET_BILLING_TYPES,
    payload: payload || {},
})
export const getBillingTypesSuccess = payload => ({
    type: GET_BILLING_TYPES_SUCCESS,
    payload: payload,
})
export const getBillingTypesFail = error => ({
    type: GET_BILLING_TYPES_FAIL,
    payload: error,
})

//SET REQUEST SAMPLE
export const setRequestSampleCollector = payload => ({
    type: SET_REQUEST_SAMPLE_COLLECTOR,
    payload: payload || {},
})

export const setRequestSampleSID = (payload, callback) => ({
    type: SET_REQUEST_SAMPLE_SID,
    payload: payload || {},
    callback
})

export const setRequestSampleSIDSuccess = payload => ({
    type: SET_REQUEST_SAMPLE_SID_SUCCESS,
    payload: payload || {},
})

export const setRequestSampleSIDFail = error => ({
    type: SET_REQUEST_SAMPLE_SID_FAIL,
    payload: error,
})

export const setRequestSampleNumbOfLabels = (payload, callback) => ({
    type: SET_REQUEST_SAMPLE_NUMB_OF_LABELS,
    payload: payload || {},
    callback
})

export const setRequestSampleNumbOfLabelsSuccess = payload => ({
    type: SET_REQUEST_SAMPLE_NUMB_OF_LABELS_SUCCESS,
    payload: payload || {},
})

export const setRequestSampleNumbOfLabelsFail = error => ({
    type: SET_REQUEST_SAMPLE_NUMB_OF_LABELS_FAIL,
    error
})

export const setRequestSampleCollectTime = payload => ({
    type: SET_REQUEST_SAMPLE_COLLECT_TIME,
    payload: payload || {},
})

export const setRequestSampleReceiver = payload => ({
    type: SET_REQUEST_SAMPLE_RECEIVER,
    payload: payload || {},
})

export const setRequestSampleReceivedTime = payload => ({
    type: SET_REQUEST_SAMPLE_RECEIVED_TIME,
    payload: payload || {},
})

export const setRequestSampleClear = payload => ({
    type: SET_REQUEST_SAMPLE_CLEAR,
    payload: payload || {},
})

export const setRequestSampleQuality = payload => ({
    type: SET_REQUEST_SAMPLE_QUALITY,
    payload: payload || {},
})
// update test request general tab
export const updateRequestGeneral = payload => ({
    type: UPDATE_REQUEST_GENERAL,
    payload: payload,
})

export const updateRequestGeneralSuccess = payload => ({
    type: UPDATE_REQUEST_GENERAL_SUCCESS,
    payload: payload,
})

export const updateRequestGeneralFail = error => ({
    type: UPDATE_REQUEST_GENERAL_FAIL,
    payload: error,
})

// update SID
export const updateRequestSID = payload => ({
    type: UPDATE_REQUEST_SID,
    payload: payload,
})

export const updateRequestSIDSuccess = payload => ({
    type: UPDATE_REQUEST_SID_SUCCESS,
    payload: payload,
})

export const updateRequestSIDFail = error => ({
    type: UPDATE_REQUEST_SID_FAIL,
    payload: error,
})


// update test request test tab
export const updateRequestTests = payload => ({
    type: UPDATE_REQUEST_TESTS,
    payload: payload,
})

export const updateRequestTestsSuccess = payload => ({
    type: UPDATE_REQUEST_TESTS_SUCCESS,
    payload: payload,
})

export const updateRequestTestsFail = error => ({
    type: UPDATE_REQUEST_TESTS_FAIL,
    payload: error,
})
//UPDATE REQUEST SAMPLE
export const updateRequestSample = payload => ({
    type: UPDATE_REQUESTSSAMPLE,
    payload: payload,
})

export const updateRequestSampleSuccess = payload => ({
    type: UPDATE_REQUESTSSAMPLE_SUCCESS,
    payload: payload,
})

export const updateRequestSampleFail = error => ({
    type: UPDATE_REQUESTSSAMPLE_FAIL,
    payload: error,
})


//get request details
export const getTestrequestGeneralDetail = (id, callback, screen) => ({
    type: GET_TESTREQUEST_GENERAL_DETAIL,
    id, callback, screen
})

export const getTestrequestGeneralDetailSuccess = request => ({
    type: GET_TESTREQUEST_GENERAL_DETAIL_SUCCESS,
    payload: request,
})

export const getTestrequestGeneralDetailFail = error => ({
    type: GET_TESTREQUEST_GENERAL_DETAIL_FAIL,
    payload: error,
})

export const getTestrequestPatientVisitGeneralDetail = (id, callback) => ({
    type: GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL,
    id, callback
})

export const getTestrequestPatientVisitGeneralDetailSuccess = request => ({
    type: GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL_SUCCESS,
    payload: request,
})

export const getTestrequestPatientVisitGeneralDetailFail = error => ({
    type: GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL_FAIL,
    payload: error,
})

export const getTestrequestTestsDetail = id => ({
    type: GET_TESTREQUEST_TESTS_DETAIL,
    id,
})

export const getTestrequestTestsDetailSuccess = request => ({
    type: GET_TESTREQUEST_TESTS_DETAIL_SUCCESS,
    payload: request,
})

export const getTestrequestTestsDetailFail = error => ({
    type: GET_TESTREQUEST_TESTS_DETAIL_FAIL,
    payload: error,
})

export const getTestrequestSamplesDetail = (id, callback) => ({
    type: GET_TESTREQUEST_SAMPLES_DETAIL,
    id, callback
})

export const getTestrequestSamplesDetailSuccess = request => ({
    type: GET_TESTREQUEST_SAMPLES_DETAIL_SUCCESS,
    payload: request,
})

export const getTestrequestSamplesDetailFail = error => ({
    type: GET_TESTREQUEST_SAMPLES_DETAIL_FAIL,
    payload: error,
})

export const getTestRequestTestsLoadingComplete = (result) => ({
    type: GET_TESTREQUEST_TESTS_LOADING_COMPLETE,
    payload: result
})

//get request details end

//change request sample
export const ChangeRequestSampleTable = (payload, callback) => ({
    type: CHANGE_REQUEST_SAMPLE_TABLE,
    payload: payload || {},
    callback: callback
})

export const ChangeRequestSampleTableSuccess = payload => ({
    type: CHANGE_REQUEST_SAMPLE_TABLE_SUCCESS,
    payload: payload || {},
})

export const ChangeRequestSampleTableFail = error => ({
    type: CHANGE_REQUEST_SAMPLE_TABLE_FAIL,
    payload: error,
})

export const reloadStateTestRequestDetail = id => ({
    type: RELOAD_STATE_TESTREQUEST_DETAIL,
    id,
})

export const reloadStateTestRequestDetailSuccess = testRequests => ({
    type: RELOAD_STATE_TESTREQUEST_DETAIL_SUCCESS,
    payload: testRequests,
})

export const reloadStateTestRequestDetailFail = error => ({
    type: RELOAD_STATE_TESTREQUEST_DETAIL_FAIL,
    payload: error,
})

//update numboflabel
export const updateNumbOfLabels = payload => {
    return ({
        type: UPDATE_SAMPLE_NUMBOFLABELS,
        payload: payload,
    })
}

export const updateNumbOfLabelsSuccess = payload => ({
    type: UPDATE_SAMPLE_NUMBOFLABELS_SUCCESS,
    payload: payload,
})

export const updateNumbOfLabelsFail = error => ({
    type: UPDATE_SAMPLE_NUMBOFLABELS_FAIL,
    payload: error,
})

export const createGroupRequest = payload => ({
    type: CREATE_GROUP_REQUEST,
    payload: payload,
})

export const editTestRequestSampleId = payload => ({
    type: EDIT_TESTREQUEST_SAMPLEID,
    payload: payload,
})

export const editTestRequestSampleIdSuccess = payload => ({
    type: EDIT_TESTREQUEST_SAMPLEID_SUCCESS,
    payload: payload,
})

export const editTestRequestSampleIdFail = error => ({
    type: EDIT_TESTREQUEST_SAMPLEID_FAIL,
    payload: error,
})

// Edit test request sample number of labels
export const editTestRequestNumbOfLabels = payload => ({
    type: EDIT_TESTREQUEST_NUMBER_OF_LABELS,
    payload: payload,
})

export const editTestRequestNumbOfLabelsSuccess = payload => ({
    type: EDIT_TESTREQUEST_NUMBER_OF_LABELS_SUCCESS,
    payload: payload,
})

export const editTestRequestNumbOfLabelsFail = error => ({
    type: EDIT_TESTREQUEST_NUMBER_OF_LABELS_FAIL,
    payload: error,
})

export const getPatientVisitSuccess = request => ({
    type: GET_PATIENT_VISIT_SUCCESS,
    payload: request,
})

export const clearDetailInfoPatientVisit = () => ({
    type: CLEAR_DETAIL_INFO_PATIENT_VISIT,
})
export const countPatientAndTest = payload => ({
    type: COUNT_PATIENT_AND_TEST,
    payload,
})

export const countPatientAndTestSuccess = payload => ({
    type: COUNT_PATIENT_AND_TEST_SUCCESS,
    payload: payload,
})

export const countPatientAndTestFail = error => ({
    type: COUNT_PATIENT_AND_TEST_FAIL,
    payload: error,
})

// Collector Area
export const addNewCollectorArea = payload => ({
    type: CREATE_NEW_COLLECTOR_AREA,
    payload: payload,
})

export const addNewCollectorAreaSuccess = payload => ({
    type: CREATE_NEW_COLLECTOR_AREA_SUCCESS,
    payload: payload,
})

export const addNewCollectorAreaFail = error => ({
    type: CREATE_NEW_COLLECTOR_AREA_FAIL,
    payload: error,
})

export const getCollectorArea = payload => ({
    type: GET_COLLECTOR_AREA,
    payload: payload,
})

export const getCollectorAreaSuccess = payload => ({
    type: GET_COLLECTOR_AREA_SUCCESS,
    payload: payload,
})

export const getCollectorAreaFail = error => ({
    type: GET_COLLECTOR_AREA_FAIL,
    payload: error,
})

export const printSampleList = val => ({
    type: PRINT_SAMPLE_LIST,
    payload: val,
})

export const loadingPrintSampleList = val => ({
    type: LOADING_PRINT_SAMPLE_LIST,
    payload: val,
})

export const updateWaitingRequest = payload => ({
    type: UPDATE_REQUEST_WAITING,
    payload: payload
})

export const updateWaitingRequestSuccess = payload => ({
    type: UPDATE_REQUEST_WAITING_SUCCESS,
    payload: payload
})

export const updateWaitingRequestFail = error => ({
    type: UPDATE_REQUEST_WAITING_FAIL,
    payload: error
})

export const updateRequestSTTGPB = payload => ({
    type: UPDATE_REQUEST_STTGPB,
    payload: payload
})

export const updateRequestSTTGPBSuccess = payload => ({
    type: UPDATE_REQUEST_STTGPB_SUCCESS,
    payload: payload
})

export const updateRequestSTTGPBFail = error => ({
    type: UPDATE_REQUEST_STTGPB_FAIL,
    payload: error
})